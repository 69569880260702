// Our main CSS
import '../css/app.css'

const btnSelector = 'button[data-lightbox-btn]'
const imgLightboxBtnsExists = document.querySelector(btnSelector)

if (imgLightboxBtnsExists && imgLightboxBtnsExists !== null) {
  console.log('imgLightboxBtnsExists')
  import('./ImageLightBox')
    .then((module) => {
      module.default(btnSelector)
    })
    .catch((err) => {
      console.log(err)
    })
}

const ArchivePostFilter = document.getElementById('ArchivePostFilter')
if (ArchivePostFilter && ArchivePostFilter !== null) {
  import('./ArchivePostFilter')
    .then((module) => {
      module.default(ArchivePostFilter as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

const FeaturedArticlesNavigation = document.querySelector('.featured-articles-navigation')
if (FeaturedArticlesNavigation && FeaturedArticlesNavigation !== null) {
  import('./FeaturedArticlesSlider')
    .then((module) => {
      module.default(FeaturedArticlesNavigation as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

const FaqLists = document.querySelectorAll('.FaqList')
if (FaqLists && FaqLists.length) {
  FaqLists.forEach((el) => {
    import('./FaqList')
      .then((module) => {
        module.default(el as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}

const autoplayingVideos = document.querySelectorAll('video[data-autoplay]')
if (autoplayingVideos && autoplayingVideos.length) {
  autoplayingVideos.forEach((video) => {
    import('./AutoplayingVideo')
      .then((module) => {
        module.default(video as HTMLMediaElement)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}

if (document.querySelector('.search-popup')) {
  import('./SearchController').then(({ SearchController }) => {
    new SearchController()
  })
}
if (document.querySelector('#main-navigation')) {
  import('./SiteMenuController').then(({ SiteMenuController }) => {
    new SiteMenuController()
  })
}

// Toggle grouped datablocks
const datablockModules = document.querySelectorAll('.datablock-module')

datablockModules.forEach((module) => {
  const datablockButtons = module.querySelectorAll('.datablock-btn')
  const datablocks = module.querySelectorAll('.datablock')

  if (datablockButtons.length > 0) {
    datablockButtons[0].classList.add('active-datablock')
    const initialCheckboxIcon = datablockButtons[0].querySelector('.checkbox-icon')
    if (initialCheckboxIcon) {
      ;(initialCheckboxIcon as HTMLElement).style.display = 'inline'
    }
  }

  datablockButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      const key = button.getAttribute('data-key')

      datablockButtons.forEach(function (btn) {
        btn.classList.remove('active-datablock')
        const checkboxIcon = btn.querySelector('.checkbox-icon')
        if (checkboxIcon) {
          ;(checkboxIcon as HTMLElement).style.display = 'none'
        }
      })

      button.classList.add('active-datablock')
      const checkboxIcon = button.querySelector('.checkbox-icon')
      if (checkboxIcon) {
        ;(checkboxIcon as HTMLElement).style.display = 'inline'
      }

      datablocks.forEach(function (block) {
        if (block.getAttribute('data-key') === key) {
          block.classList.remove('hidden')
        } else {
          block.classList.add('hidden')
        }
      })
    })
  })
})

// Category popups
document.addEventListener('DOMContentLoaded', () => {
  const icons = document.querySelectorAll('div[id^="categoryHelpPopupIcon-"]')
  const popups = document.querySelectorAll('.category-popup-text')
  const closes = document.querySelectorAll('div[id^="closeCategory-"]')

  icons.forEach((icon: Element, index: number) => {
    icon.addEventListener('click', function (event: Event) {
      event.stopPropagation()
      popups.forEach((popup: Element, popupIndex: number) => {
        if (popupIndex !== index && popup.classList.contains('show')) {
          popup.classList.remove('show')
        }
      })
      if (popups[index]) {
        ;(popups[index] as Element).classList.toggle('show')
      }
    })
  })

  closes.forEach((close: Element, index: number) => {
    close.addEventListener('click', function (event: Event) {
      event.stopPropagation()
      if (popups[index]) {
        ;(popups[index] as Element).classList.remove('show')
      }
    })
  })

  window.addEventListener('click', function () {
    popups.forEach((popup: Element) => {
      if (popup.classList.contains('show')) {
        popup.classList.remove('show')
      }
    })
  })
})
